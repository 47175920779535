import '@babel/polyfill';

// jQuery
// import $ from 'jquery';

// Jsモジュール
/* eslint-disable */
import {scrollTop} from './modules/scrollTop';
import {isMobile} from './modules/isMobile';
/* eslint-disable */

// Sass
import '../scss/style.scss';

const getPost = () => {
  const form = document.getElementById('form');
  if(form === null) {
    return false;
  }
  // const form = document.getElementById('form');
  const description = document.getElementById('description');
  const place = document.getElementById('place');
  const descriptions = document.querySelectorAll('.description');
  const places = document.querySelectorAll('.place');

  description.addEventListener('change', () => {
    place.value = '';
    form.submit();
  }, false);

  place.addEventListener('change', () => {
    description.value = '';
    form.submit();
  }, false);

  for(let i = 0, l = descriptions.length; i < l; i++) {
    if(descriptions[i].value === form.dataset.desc) {
      descriptions[i].selected = true;
    }
  }

  for(let i = 0, l = places.length; i < l; i++) {
    if(places[i].value === form.dataset.place) {
      places[i].selected = true;
    }
  }
}
getPost();

const current = () => {
  const btnNavs = document.getElementsByClassName('btn-nav');
  const locHref = location.href;
  if(locHref.indexOf('classes') === -1 && locHref.indexOf('address') === -1) {
    btnNavs[0].classList.add('btn-success');
    btnNavs[0].parentNode.classList.add('filter');
  }
  if(locHref.indexOf('address') !== -1) {
    btnNavs[1].classList.add('btn-success');
    btnNavs[1].parentNode.classList.add('filter');
  }
}

current();